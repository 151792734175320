body {
  margin: 0;
  font-family: 'Muli', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

* {
    padding: 0;
    margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	text-decoration: none;
	color:#b04995;
}
.page-container{
	position: absolute;
	top: 0; 
	right: 0; 
	left: 0; 
	bottom: 0;
}

h1 {
	font-size: 20px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.pointer{
cursor: pointer;
}

.box-shadow{
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 0px 20px 0 rgba(0, 0, 0, 0.05)
}